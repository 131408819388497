@keyframes zoomIn1 {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes zoomOut1 {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  
  .zoom-in1 {
    animation: zoomIn 1.5s ease-in-out forwards;
  }
  
  .zoom-out1 {
    animation: zoomOut 2.5s ease-in-out forwards;
  }
  
  