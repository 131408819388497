.table-header {
  color: #ffffff;
  background-color: #3c979f;
  font-size: 10px;
  padding: 10px;
}

.loading-container, .error-container, .empty-container {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
}

.responsive-table {
  width: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.icon-button {
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.project-list-table {
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-size: 11px;
  table-layout: fixed; /* Ensure table fits within the container */
  width: 100%;
}

.project-list-table th, .project-list-table td {
  text-align: center; /* Center horizontally */
  vertical-align: middle; /* Center vertically */
  padding: 5px 3px; /* Adjust padding */
  word-wrap: break-word; /* Allow word wrapping in table cells */
  white-space: normal; /* Allow wrapping */
}
/* Define width for each column separately */
.project-list-table th:nth-child(1), .project-list-table td:nth-child(1) {
  width: 5%;
}

.project-list-table th:nth-child(2), .project-list-table td:nth-child(2) {
  width: 7%;
}

.project-list-table th:nth-child(3), .project-list-table td:nth-child(3) {
  width: 6%;
}

.project-list-table th:nth-child(4), .project-list-table td:nth-child(4) {
  width: 8%;
}

.project-list-table th:nth-child(5), .project-list-table td:nth-child(5) {
  width: 12%;
}

.project-list-table th:nth-child(6), .project-list-table td:nth-child(6) {
  width: 10%;
}

.project-list-table th:nth-child(7), .project-list-table td:nth-child(7) {
  width: 7%;
}

.project-list-table th:nth-child(8), .project-list-table td:nth-child(8) {
  width: 10%;
}

.project-list-table th:nth-child(9), .project-list-table td:nth-child(9) {
  width: 9%;
}

.project-list-table th:nth-child(10), .project-list-table td:nth-child(10) {
  width: 9%;
}

.project-list-table th:nth-child(11), .project-list-table td:nth-child(11) {
  width: 9%;
}

.project-list-table th:nth-child(12), .project-list-table td:nth-child(12) {
  width: 9%;
}

.project-list-table th:nth-child(13), .project-list-table td:nth-child(13) {
  width: 9.5%;
}

.project-list-table th:nth-child(14), .project-list-table td:nth-child(14) {
  width: 9%;
}

.project-list-table th:nth-child(15), .project-list-table td:nth-child(15) {
  width: 9%;
}

.project-list-table th:nth-child(16), .project-list-table td:nth-child(16) {
  width: 7%;
}

.project-list-table th:nth-child(17), .project-list-table td:nth-child(17) {
  width: 5%;
}

.project-list-table th:nth-child(18), .project-list-table td:nth-child(18) {
  width: 5%;
}

.project-list-table th:nth-child(19), .project-list-table td:nth-child(19) {
  width: 5%;
}

.project-list-table tbody tr:hover {
  background-color: #f9f9f9;
}