/* Global Styles */
body {
  background-color: #f4f6f9;
  font-family: 'Arial', sans-serif;
}

/* Card Styling */
.card {
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.card-body {
  padding: 20px;
}

/* Transition Animation */
.cards-enter {
  opacity: 0;
  transform: translateX(100%);
}
.cards-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}
.cards-exit {
  opacity: 1;
  transform: translateX(0);
}
.cards-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

/* Button Styles */
button {
  border-radius: 30px;
  padding: 12px 30px;
  font-weight: bold;
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}

/* Alert Styling */
.alert {
  margin-top: 10px;
  border-radius: 8px;
}

/* Back and Next Button */
button:focus, .btn-outline-secondary, .btn-outline-primary {
  box-shadow: none;
  border-color: #007bff;
}

/* Back Button */
button.btn-outline-secondary {
  color: #6c757d;
}

/* Next and Submit Buttons */
button.btn-outline-primary {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}

button.btn-outline-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Logo Image */
img {
  border-radius: 50%;
  transition: transform 0.2s ease;
}

img:hover {
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .card {
    width: 100%;
    margin: 10px 0;
  }
  .d-flex {
    flex-direction: column;
    align-items: stretch;
  }
  .w-75 {
    width: 100%;
  }
}