/* Collapsed sidebar: only icons visible */
.sidebar {
  position: fixed;
  left: 0;
  top: 95px !important; /* Adjust based on your navbar height */
  bottom: 0;
  width: 100px !important; /* Fixed collapsed width */
  background-color: #2b3e50;
  color: #fff;
  padding: 20px 10px;
  font-size: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  overflow: visible; /* Allow tooltips to overflow */
}

/* Navigation link container for tooltip positioning */
.nav-link-container {
  margin-bottom: 12px;
  position: relative; /* For absolute positioning of tooltip */
}

/* Navigation links styling */
.nav-link {
  display: flex;
  align-items: center;
  justify-content: center; /* Center icon in collapsed sidebar */
  color: #d0d6dc;
  padding: 12px;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease;
  border-left: 3px solid transparent;
  box-sizing: border-box;
}

/* Hover state for nav links */
.nav-link:hover {
  background-color: #4b5d6c;
  color: #ffffff;
  border-left: 3px solid #3498db;
}

/* Active state for nav links */
.nav-link.active {
  background-color: #1c2733;
  color: #ffffff;
  font-weight: 600;
  border-left: 3px solid #3498db;
}

/* Icon styling */
.nav-link svg {
  font-size: 18px;
}

/* Tooltip styling for description */
.nav-link span {
  position: absolute;
  left: 70px; /* Position tooltip to the right of the icon */
  top: 50%;
  transform: translateY(-50%);
  background-color: #2b3e50;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

/* Show tooltip only on hovering over individual nav-link container */
.nav-link-container:hover span {
  opacity: 1;
}

/* Mobile responsiveness if needed */
@media (max-width: 768px) {
  .sidebar {
    width: 60px; /* Keep consistent collapsed width on mobile */
    font-size: 12px;
  }
  .nav-link svg {
    font-size: 16px;
  }
}
