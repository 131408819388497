/* General container */
.container {
    width: 70%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading */
  h1 {
    font-size: 2rem;
    color: #333;
    text-align: center;
  }
  
  /* Form and Select Inputs */
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  input, select {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  input:focus, select:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  button {
    padding: 10px;
    font-size: 1rem;
    background-color: #4d997b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* User Info Display */
  .profile-info {
    margin-bottom: 20px;
  }
  
  .profile-info p {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }
  
  .profile-info strong {
    color: #555;
  }
  
  .select-container {
    margin-bottom: 15px;
  }
  
  .select-container select {
    width: 100%;
  }
  
  /* Error message */
  .error {
    color: red;
    font-weight: bold;
    text-align: center;
  }
  
  /* Button container */
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  button:disabled {
    background-color: #ccc;
  }
  