.seg-view-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-card-header {
  background-color: #2b5278;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.custom-card p {
  margin: 5px 0;
  font-size: 0.95rem;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
}
