/* Sidebar container */
.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 100px !important;;  /* Adjust according to navbar height */
  bottom: 0;
  width: 180px;
  background-color: #0a4b54;
  color: #fff;
  padding: 10px 10px;
  font-size: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  transition: width 0.3s ease-in-out;
}

/* Navigation container */
.flex-column {
  margin-top: 0px;
  padding: 0 10px;
}

/* Navigation link container */
.nav-link-container {
  margin-bottom: 12px;
}

.always-visible-badge {
  display: inline-block !important;
  opacity: 1 !important;
  border-radius: 10px;
}

/* Navigation link styling */
.nav-link {
  display: flex;
  align-items: center;
  gap: 4px !important;
  color: #d0d6dc;
  padding: 12px 16px;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease;
  border-left: 3px solid transparent;
  box-sizing: border-box;
  font-size: 1rem;
}

/* Hover state */
.nav-link:hover {
  background-color: #4b5d6c;
  color: #ffffff;
  border-left: 3px solid #3498db;
}

/* Active state */
.nav-link.active {
  background-color: #1c2733;
  color: #ffffff;
  font-weight: 600;
  border-left: 3px solid #3498db;
}

/* Icon styling */
.sidebar-list-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 25%;
    font-size: 0.9rem;
  }
  .sidebar-list-icon {
    width: 18px;
    height: 18px;
  }
}

/* Extra spacing */
.nav-link-container:first-child {
  margin-top: 20px;
}
.nav-link-container:last-child {
  margin-bottom: 0;
}
