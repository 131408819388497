/* custom.css */
.document-manager-header {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.card-header {
    background-color: #f8f9fa;
    font-size: 1.2rem;
}

.file-input-small {
    max-width: 200px;
}

.btn {
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}
