/* General Styles */
body {
  background: #f4f7fc;  /* Light blue-gray background */
  font-family: 'Inter', sans-serif;
  padding-top: 80px; /* Spacing from navbar */
  margin: 0;
  color: #333;
}

/* Container */
.container {
  max-width: 100%;
  padding: 16px;
  margin: 0 auto;
}

/* Form Elements */
.form-control,
.form-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
}

.form-control:focus,
.form-select:focus {
  border-color: #007bff;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.2);
}

/* Buttons */
.btn-primary {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #fff;
  border: none;
  padding: 10px 18px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-primary:hover {
  background: linear-gradient(135deg, #0056b3, #004494);
}

/* Accordion */
.accordion-item {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.accordion-button {
  font-size: 16px;
  font-weight: 500;
  background: #007bff;
  color: #ffffff;
  padding: 12px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  transition: background 0.3s;
}

.accordion-button:hover {
  background: #0056b3;
}

.accordion-button:after {
  display: none;
}

/* Accordion Body */
.accordion-body {
  padding: 15px;
  max-height: 180px;
  overflow-y: auto;
  background: #f9f9f9;
}


.table-container {
  width: auto;  /* Ensures the table takes only the required space */
  border-collapse: collapse;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  margin-left: 0 !important; /* Enforce left alignment */
  text-align: left;
  float: left; /* Forces the table to move left */
}
.container {
  padding: 16px;
  margin: 0 auto;
  max-width: none; /* Remove width limitation */
}


.table-container th {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #ffffff;
  padding: 12px;
  font-size: 14px;
}

.table-container td {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Table Row Hover Effect */
.table-container tr:hover {
  background: rgba(0, 123, 255, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 12px;
  }

  .form-control,
  .form-select {
    padding: 10px;
  }

  .btn-primary {
    padding: 8px 16px;
  }

  .table-container th,
  .table-container td {
    font-size: 12px;
    padding: 8px;
  }
}
