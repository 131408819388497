.modal {
    display: block; 
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  .modal-dialog {
    margin: 100px auto; 
  }
  
  .timesheet-container {
    padding-top: 20px; /* adjust the value as needed */
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    height: 100vh;
  }
  
  .modal-content {
    padding: 20px;
  }
  
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 10px 20px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  