/* General container for the driver analysis */
.driver-analysis-container {
    max-width: 1200px;  /* Increased width for better content spacing */
    margin: 30px auto;  /* Centered with a top and bottom margin */
    padding: 40px;
    background-color: #ffffff;  /* White background for cleaner look */
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);  /* Slightly heavier shadow for depth */
    font-family: 'Arial', sans-serif;
}

/* Title styling */
.title {
    text-align: center;
    color: #333;
    font-size: 2rem;  /* Larger title font size */
    font-weight: 600;
    margin-bottom: 30px;
}

/* Form styling */
.form-group {
    margin-bottom: 20px; /* Increased space for a cleaner look */
}

/* Label styling for better accessibility */
label {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    color: #444;
    font-size: 1rem; /* Slightly larger font for better readability */
}

/* Select input styling */
.select-input {
    width: 100%;
    padding: 12px;  /* Increased padding for better touch targets */
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fafafa;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.select-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

/* Submit button styling */
.submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

/* Error and success message styling */
.error-message, .success-message {
    font-size: 1rem;
    font-weight: 500;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    animation: fadeIn 0.5s ease;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
}

/* Table styling */
.results-table {
    width: 100%;
    margin-top: 30px;
    border-collapse: collapse;
    box-sizing: border-box;
    overflow: hidden;
}

.results-table th, .results-table td {
    padding: 15px;  /* Added more padding for better spacing */
    border: 1px solid #ddd;
    text-align: center;
    vertical-align: middle; /* Center content vertically */
}

.results-table th {
    background-color: #007bff;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 600;
}

.results-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.results-table td {
    font-size: 0.95rem;
    word-wrap: break-word;
    white-space: normal;
}

.results-table td:first-child, .results-table th:first-child {
    max-width: 400px;
    text-align: left;
    word-wrap: break-word;
}

/* Scrollable container for long file lists */
.scrollable-container {
    max-height: 400px;
    overflow-y: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
    .driver-analysis-container {
        padding: 20px;
        margin: 20px;
    }

    .title {
        font-size: 1.5rem;
    }

    .submit-button {
        font-size: 1rem;
    }

    .results-table th, .results-table td {
        font-size: 0.9rem;
        padding: 10px;
    }
}

/* Animation for fading in error and success messages */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}