.segment-details-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
  }
  
  .field-container {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .custom-datepicker {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .card-header {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .shadow-sm {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  .highlighted-label {
    font-weight: bold;
    color: gray; /* Bootstrap Primary color */
  }
  