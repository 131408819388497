.custom-tabs .nav-link {
  color: #0056b3;
  font-weight: 600;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin-right: 5px;
}

.custom-tabs .nav-link.active {
  background-color: #0056b3;
  color: #ffffff !important;
  border-radius: 8px;
  font-weight: bold;
}

.custom-tabs .nav-link:hover {
  background-color: #e9ecef;
  color: #0056b3;
  border-radius: 8px;
}

.tab-content-container {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.card-person {
  cursor: pointer;
  width: 200px;
  border: 2px solid;
  border-color: #ccc;
  transition: all 0.3s ease;
}

.card-person.border-primary {
  border-color: #0d6efd;
}

.card-person:hover {
  border-color: #0d6efd;
}

.card-person-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}