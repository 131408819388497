:root {
  --navbar-height: 100px; /* Set your default navbar height */
}

.navbar {
  display: flex;
  justify-content: space-between; /* Align logo and links properly */
  align-items: center;
  background-color: #f8f9fa; /* Match sidebar background */
  padding: 15px 20px; /* Adjust padding for better spacing */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
  border-bottom: 1px solid #ddd; /* Match sidebar border */
  height: var(--navbar-height);
}

.navbar-nav {
  display: flex;
  justify-content: flex-end; /* Align links to the right */
  align-items: center;
  margin: 0;
  width: auto; /* Allow navbar items to adjust dynamically */
}

.nav-link {
  padding: 10px 15px;
  font-size: 16px; /* Match sidebar font size */
  color: #333; /* Neutral color to match the sidebar */
  text-decoration: none;
  transition: all 0.3s ease;
  border-radius: 5px; /* Rounded edges for hover effect */
}

.nav-link:hover {
  background-color: #e9ecef; /* Match hover effect with sidebar links */
  color: #007bff; /* Accent color for hover */
}

.logo {
  width: 120px; /* Adjust size for better visibility */
  height: auto;
  object-fit: contain;
  
}

.navbar-logo img.original-logo {
  width: auto; /* Maintain original aspect ratio */
  height: auto;
  max-height: 90px; /* Ensure it doesn’t get too large */
  max-width: 100%;
  border-radius: 0; /* Prevents rounding */
  object-fit: unset; /* Ensures the image is not stretched or cropped */
  margin-top: 0px;
}
.custom-dropdown .dropdown-toggle::after {
  background-color: #39816f;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 3px;
}

.dropdown-menu[data-bs-popper] {
  top: -10%;
  left: -160px;
  margin-top: var(--bs-dropdown-spacer);
}
.username {
  color: #f3f3e9; /* Match nav-link color */
  font-weight: 500; /* Subtle emphasis */
  padding: 5px 5px;
}


.operations {
  color: #fbfbfb; /* Match nav-link color */
  font-weight: 500; /* Subtle emphasis */
   
}
.custom-dropdown:hover > .nav-dropdown-login, 
.custom-dropdown:hover > .username {
  color: #cccccc; /* Set a light gray hover color */
  cursor: pointer; /* Change the cursor to a pointer */
  text-decoration: underline; /* Optional: Add an underline on hover */
}

.username:hover,
.custom-dropdown .dropdown-toggle:hover::after {
  background-color: #e9ecef; /* Match hover effect with nav-link */
  color: #00a1e1; /* Accent color */
  border-radius: 2px;
  padding: 3px;
}

body {
  padding-top: 80px; /* Adjust padding to accommodate fixed navbar */
  background-color: #f8f9fa; /* Match overall background */
}
.container, .main-content {
  margin-top: 80px; /* Add space below navbar */
}