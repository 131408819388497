.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-image: url('./6363.jpg'); */
  background-color: #F1F1F1;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: -1; 
}

.custom-label {
  font-weight: bold;        /* Make the text bold */
  color: #062bf9;           /* Highlight with a custom color (orange in this case) */
  font-size: 1.2rem;        /* Increase the font size slightly */
}

/* .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom, #F1F1F1 50%, #9b51e0 50%);
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(50px);
  z-index: -1; 
} */





