/* Sidebar container remains fixed */
.ts-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  top: 100px; /* Adjust based on your navbar height */
  bottom: 0;
  width: 100px; /* Fixed width for icons */
  background-color: #0a4b54; /* Subtle dark theme */
  color: #fff;
  padding: 20px 10px; /* Uniform padding */
  font-size: 1rem; /* Standard font size */
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.15); /* Shadow for depth */
  z-index: 1000; /* Ensure it's behind the navbar */
  transition: width 0.3s ease-in-out;
  overflow: visible; /* Allow tooltips to overflow */
}

/* Sidebar navigation container */
.ts-flex-column {
  margin-top: 20px; /* Moves content inside the sidebar down */
  padding: 0 10px;
}

/* Each navigation link container */
.ts-nav-link-container {
  margin-bottom: 8px; /* Uniform spacing between items */
  position: relative; /* For positioning the tooltip */
}

/* Navigation links */
.ts-nav-link {
  display: flex;
  align-items: center;
  justify-content: center; /* Center icon in narrow sidebar */
  gap: 2px !important; /* Space between icon and text (if visible) */
  color: #d0d6dc;
  padding: 20px 20px;
  border-radius: 5px;
  text-decoration: none; /* Remove underline */
  transition: background 0.3s ease, color 0.3s ease;
  border-left: 3px solid transparent; /* Default border */
  box-sizing: border-box;
  font-size: 1rem;
  margin-left: 10px;
}

/* Hover effect for nav links */
.ts-nav-link:hover {
  background-color: #4b5d6c;
  color: #ffffff;
  border-left: 3px solid #3498db;
}

/* Active state */
.ts-nav-link.ts-active {
  color: #ffffff;
  background-color: #1c2733;
  font-weight: 600;
  border-left: 3px solid #3498db;
}

/* Icon styling remains unchanged */
.ts-nav-link svg {
  font-size: 18px;
}

/* Hide the description text in the link by default */
.ts-nav-link span {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 110px; /* Positions tooltip to the right of the sidebar */
  top: 50%;
  transform: translateY(-50%);
  background-color: #0a4b54;
  padding: 5px 8px;
  border-radius: 3px;
  white-space: nowrap;
  transition: opacity 0.3s ease;
  z-index: 2000;
}

/* Show tooltip only when hovering over its container */
.ts-nav-link-container:hover span {
  opacity: 1;
  visibility: visible;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .ts-sidebar {
    width: 100px; /* Keeping a fixed width for icons on mobile */
    font-size: 12px;
  }
  .ts-nav-link svg {
    font-size: 16px;
  }
}

/* Spacing for last item */
.ts-nav-link-container:last-child {
  margin-bottom: 0;
}
