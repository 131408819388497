/* Main Form Container Styling */
.custom-class {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  background: linear-gradient(135deg, #fefefe, #f5f5f5);
  padding: 20px;
  transition: box-shadow 0.4s ease, transform 0.3s ease;
}

.custom-class:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
}

/* Form Group Label Styling */
.custom-form-label {
  color: #333;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f5;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.custom-form-label:hover {
  background-color: #e0e0eb;
}

/* Date Picker and Input Fields */
.custom-date-picker,
.form-control {
  background-color: #ffffff;
  border: 2px solid #dcdcdc;
  border-radius: 8px;
  padding: 12px 15px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #555;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-date-picker:focus,
.form-control:focus {
  border-color: #6c63ff;
  box-shadow: 0 0 8px rgba(108, 99, 255, 0.4);
  outline: none;
}

.form-control::placeholder {
  color: #999;
  font-style: italic;
}

/* Next and Submit Button Styles */
.awnB {
  background: linear-gradient(135deg, #6c63ff, #897aff);
  color: white;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  border: none;
  padding: 12px 25px;
  border-radius: 10px;
  transition: background 0.4s ease, transform 0.3s ease;
}

.awnB:hover {
  background: linear-gradient(135deg, #897aff, #6c63ff);
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(108, 99, 255, 0.3);
}

.awnB:active {
  background: #5447d7;
  transform: translateY(0);
}

/* Download Button Styling */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
  border: 2px solid #198754;
  background: linear-gradient(135deg, #28a745, #1e7e34);
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  position: relative;
  transition: background 0.4s ease, transform 0.3s ease;
}

.button:hover {
  background: linear-gradient(135deg, #1e7e34, #28a745);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(40, 167, 69, 0.3);
}

.button__text {
  font-weight: bold;
  margin-right: 8px;
}

.svg {
  fill: #ffffff;
  width: 24px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .custom-class {
    padding: 15px;
  }
  
  .custom-date-picker {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .custom-date-picker {
    width: 130%;
  }
}
